<template>
  <div>
    <div class="block-slide-toggle"
      v-for="(accordion, index) in accordionName"
      :key="index"
    >
      <Accordion :hidden="$root.edit.can_edit_contractor" :data="id" :name="accordion" @update="$emit('updateData')" />
      <div class="decor-line"></div>
    </div>
  </div>
</template>


<style lang="scss">
  .block-slide-none{
    color: #8EB6E3;
    font-size: 16px;
    text-align: center;
    margin: 20px 0;
  }
</style>



<script>
import Accordion from '@/components/counterparties/Accordion'


export default {
  props: ['id'],
  data: () => ({
    accordionName: [
      {name: 'Карта клієнта', mode: 'customerCard', bd_name: 'countries_carriage'},
      {name: 'Напрямки перевезень', mode: 'directions', bd_name: 'countries_carriage'},
      {name: 'Транспорт компанії', mode: 'transport_companies', bd_name: 'transport_companies'},
      {name: 'Менеджери', mode: 'managers', bd_name: 'contacts_persons'},
      {name: 'Угоди', mode: 'orders', bd_name: 'orders'},
      {name: 'Підлеглі перевізники', mode: 'carriers', bd_name: 'subordinate_carriers'},
      {name: 'Водії', mode: 'drivers', bd_name: 'drivers'},
      {name: 'Автомобілі', mode: 'car', bd_name: 'trucks'},
      {name: 'Причепи', mode: 'trailers', bd_name: 'trailers'},
      {name: 'Рекомендації відправлення', mode: 'recommendations', bd_name: 'subordinate_carriers'},
    ],
  }),
  created() {
    this.accordionName[0].name = this.$root.langText.menu.customerCard
    this.accordionName[1].name = this.$root.langText.menu.directions
    this.accordionName[2].name = this.$root.langText.menu.transportCompanies
    this.accordionName[3].name = this.$root.langText.menu.managers
    if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
      this.accordionName[4].name = this.$root.langText.directory.order
    } else{
      this.accordionName[4].name = this.$root.langText.directory.orderOther
    }
    this.accordionName[5].name = this.$root.langText.menu.carriers
    this.accordionName[6].name = this.$root.langText.directory.drivers
    this.accordionName[7].name = this.$root.langText.directory.car
    this.accordionName[8].name = this.$root.langText.directory.trailers
    this.accordionName[9].name = this.$root.langText.menu.recommendations

    if(this.$route.params.id){
      if(this.$root.app == 'SoftProCrmAppRioTransTest' || this.$root.app == 'SoftProCrmAppRioTrans'){
        this.accordionName[4].name = 'Рейси'
        if(this.id.is_carrier == 1 && this.id.isClient == 1){
          if(this.id.is_main_contractor != 1){
            this.accordionName.splice(5, 1)
          }
        }else if(this.id.is_carrier == 1){
          this.accordionName.splice(9, 1)
          if(this.id.is_main_contractor != 1){
            this.accordionName.splice(5, 1)
          }
          this.accordionName.splice(0, 1)
        } else{
          this.accordionName.splice(8, 1)
          this.accordionName.splice(7, 1)
          this.accordionName.splice(6, 1)
          this.accordionName.splice(5, 1)
          if(this.id.client_id == 0){
            this.accordionName.splice(5, 1)
          }
          this.accordionName.splice(2, 1)
          this.accordionName.splice(1, 1)
        }
      } else{
        this.accordionName.splice(9, 1)
        this.accordionName.splice(8, 1)
        this.accordionName.splice(7, 1)
        this.accordionName.splice(6, 1)
        this.accordionName.splice(5, 1)
        this.accordionName.splice(4, 1)
        this.accordionName.splice(2, 1)
        this.accordionName.splice(1, 1)
        this.accordionName.splice(0, 1)
      }
    }
  },
  
  components: {
    Accordion
  }
}
</script>