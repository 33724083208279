<template>
  <div class="container">
    <div class="tabs"
      :class="$root.edit.can_edit_contractor ? '' : 'hide'"
      v-if="counterpartiesId"
    >
      <ul class="tabs__caption" v-if="$route.params.id">
        <li @click="activetab=1" v-bind:class="[ activetab === 1 ? 'active' : '' ]">{{$root.langText.general}}</li>
        <li @click="activetab=2" v-bind:class="[ activetab === 2 ? 'active' : '' ]">{{$root.langText.detail}}</li>
      </ul>
      
      <!-- tab 1 -->
      <div class="tabs__content" v-if="activetab === 1 && counterpartiesId !== null">
        <CounterpartiesInfo :id="counterpartiesId" @updateData="updateData"/>
      </div>
  

      <!-- tab 2 -->
      <div class="tabs__content" v-if="activetab === 2">
        <CounterpartiesDetail :id="counterpartiesId" @updateData="updateData"/>
      </div>


    </div>
  </div>
</template>


<style lang="scss">
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }
  .fade-enter, .fade-leave-to /* .fade-leave-active до версии 2.1.8 */ {
    opacity: 0;
  }
  .decor-line{
    margin: 20px 0;
  }
</style>



<script>
import CounterpartiesInfo from '@/components/counterparties/CounterpartiesInfo'
import CounterpartiesDetail from '@/components/counterparties/CounterpartiesDetail'


export default {
  data: () => (
    { 
      activetab: 1, 
      counterpartiesId: null,

      //Title
      globalTitle: [{title: 'Новий контрагент', info: 'Контрагент'}],
    }
  ),
  watch: {
    async $route(to, from) {
      let app = this
      app.activetab = 1
      if(app.$route.params.id){
        const id = app.$route.params.id
        const res = await app.$store.dispatch('getСounterpartiesId', id)
        app.counterpartiesId = res.data.detail
        //Title
        app.$root.title = app.globalTitle
        app.$root.title[0].title = app.counterpartiesId.name
        app.$root.title[0].info = this.$root.langText.input.counterparty
      } else{
        app.create = true
        app.counterpartiesId = []
        //title
        app.$root.title = [{title: this.$root.langText.newSecondary + ' ' + this.$root.langText.input.counterparty.toLowerCase(), info: ''}]
      }
    }
  },



  async mounted(){
  
    if(this.$route.params.id){
      const id = this.$route.params.id
      const res = await this.$store.dispatch('getСounterpartiesId', id)
      this.counterpartiesId = res.data.detail

      if(this.counterpartiesId){
      

        //Title

        this.$root.title[0].title = this.counterpartiesId.name
        this.$root.title[0].info = this.$root.langText.input.counterparty
      }
    } else{
      this.create = true
      this.counterpartiesId = []
      //title
      this.globalTitle[0].title = this.$root.langText.newSecondary + ' ' + this.$root.langText.input.counterparty.toLowerCase();
      this.globalTitle[0].info = '';
      this.$root.title = this.globalTitle
    }


  },
  methods: {
    async updateData(){
      const id = this.$route.params.id
      const res = await this.$store.dispatch('getСounterpartiesId', id)
      this.counterpartiesId = res.data.detail
    }
  },

  beforeDestroy() {
    localStorage.removeItem('MainContractor')
    localStorage.removeItem('clientId')
  },

  destroyed() {
    this.$root.data.counterparties.info = ''
    this.$root.data.counterparties.detail = {}
  },



  components: {
    CounterpartiesInfo, CounterpartiesDetail
  },
  metaInfo(){
    return {
      title: this.$title(this.$root.langText.directory.counterparties)
    }
  },
}
</script>