<template>
  <div>
    <div class="block-slide-toggle__btn">
      <button
        v-if="name.mode == 'customerCard' || name.mode == 'recommendations'"
        @click.prevent
      >
        {{ name.name }}
      </button>
      <button v-else @click.prevent>{{ name.name }} ({{ listsLength }})</button>
    </div>
    <transition name="slide-fade" mode="out-in">
      <div class="block-slide-toggle__content" v-if="fade">
        <!-- customerCard -->
        <div class="block-list" v-if="name.mode == 'customerCard'">
          <div class="form__row form__row-50">
            <div class="form__radio">
              <label>
                <input
                  type="radio"
                  name="export_import"
                  value="1"
                  v-model="export_import"
                />
                <span>{{$root.langText.input.imports}}</span>
              </label>
            </div>

            <div class="form__radio">
              <label>
                <input
                  type="radio"
                  name="export_import"
                  value="2"
                  v-model="export_import"
                />
                <span>{{$root.langText.input.export}}</span>
              </label>
            </div>
          </div>

          <div class="form__input" v-if="ifStatusText(comments)">
            <p class="form__input__textarea-p">{{ comments }}</p>
            <textarea
              class="form__input__textarea"
              type="text"
              v-model="comments"
            />
            <span>{{$root.langText.input.comments}}</span>
          </div>
          <a
            class="btn-save btn btn-primary"
            v-if="$root.edit.can_edit_contractor"
            href="#"
            @click.prevent="send"
            >{{$root.langText.save}}</a
          >
        </div>

        <!-- recommendations -->
        <div class="block-list" v-else-if="name.mode == 'recommendations'">
          <div class="form__radio">
            <label>
              <input
                type="radio"
                name="recommendations"
                value="1"
                v-model="recommendations"
              />
              <span>{{$root.app !== 'SoftProCrmAppRioTransTest' && $root.app !== 'SoftProCrmAppRioTrans' ? $root.langText.input.accountOrders : $root.langText.input.accountOrders}}</span>
            </label>
          </div>

          <div class="form__radio">
            <label>
              <input
                type="radio"
                name="recommendations"
                value="2"
                v-model="recommendations"
              />
              <span>{{$root.langText.input.monthlyBill}}</span>
            </label>
          </div>

          <div class="form__radio">
            <label>
              <input
                type="radio"
                name="recommendations"
                value="3"
                v-model="recommendations"
              />
              <span>{{$root.langText.input.accountSeparately}}</span>
            </label>
          </div>
          <div class="form__input">
            <input type="email" placeholder="@mail.com" v-model="email" />
            <span>{{$root.langText.input.emailMailing}}</span>
          </div>

          <div class="form__input" v-if="ifStatusText(address)">
            <p class="form__input__textarea-p">{{ address }}</p>
            <textarea
              class="form__input__textarea"
              type="text"
              v-model="address"
            />
            <span>{{$root.langText.input.mailingAddress}}</span>
          </div>

          <div class="form__input" v-if="ifStatusText(comments)">
            <p class="form__input__textarea-p">{{ comments }}</p>
            <textarea
              class="form__input__textarea"
              type="text"
              v-model="comments"
            />
            <span>{{$root.langText.input.comments}}</span>
          </div>
          <a
            class="btn-save btn btn-primary"
            v-if="$root.edit.can_edit_contractor"
            @click.prevent="send"
            >{{$root.langText.save}}</a
          >
        </div>

        <div v-else>
          <ul class="block-list">
            <li
              v-for="(list, index) in lists"
              :key="index"
              :data-id="list.id"
              class="block-list__item"
              :class="name.mode"
            >
              <div @click.prevent="openList('update', index)">
                <!-- directions -->
                <div v-if="name.mode == 'directions'">
                  <span>{{ list.is_main == "1" ? "Основна" : "" }}</span>
                  <h5>{{ list.country_name }}</h5>
                  <span>{{$root.langText.input.manager}}</span>
                  <p>
                    {{ list.manager_name !== "" ? list.manager_name : "-" }}
                  </p>
                </div>

                <!-- transport_companies -->
                <div v-if="name.mode == 'transport_companies'">
                  <h4>{{ list.container_type_name }}</h4>
                  <span>{{$root.langText.input.quantity}}</span>
                  <p>{{ list.num }}</p>
                  <span>{{$root.langText.input.liftingCapacity2}}</span>
                  <p>{{ list.lifting_capacity }}</p>
                  <span>{{$root.langText.input.volume2}}</span>
                  <p>{{ list.cargo_volume }}</p>
                </div>

                <!-- managers -->
                <div v-if="name.mode == 'managers'">
                  <h5>{{ list.name }}</h5>
                  <span style="margin-top: -5px; margin-bottom: 10px">{{
                    list.position
                  }}</span>
                  <div v-if="list.name">
                    <p v-if="list.contacts && list.contacts[0]">
                      {{ list.contacts[0].val }}
                    </p>
                    <p v-if="list.contacts && list.contacts[1]">
                      {{ list.contacts[1].val }}
                    </p>
                  </div>
                </div>

                <!-- orders -->
                <div v-if="name.mode == 'orders'">
                  <i :style="{ background: list.status_color }"></i>
                  <strong :style="{ color: list.status_color }">{{
                    list.status_name
                  }}</strong>
                  <h4>
                    {{$root.app !== 'SoftProCrmAppRioTransTest' && $root.app !== 'SoftProCrmAppRioTrans' ? $root.langText.directory.orderOtherOne : $root.langText.directory.orderOne}} #{{ list.order_number }}
                    <p>
                      {{$root.langText.from}} {{ list.created_at | date }}
                    </p>
                  </h4>
                  <p>{{ list.client_name ? list.client_name : "-" }}</p>
                </div>

                <!-- carriers -->
                <div v-if="name.mode == 'carriers'">
                  <span>{{$root.langText.input.carrier}}</span>
                  <h4>{{ list.name }}</h4>
                  <span>{{$root.langText.input.legalForm}}</span>
                  <p>{{ list.entity_physical_person_name }}</p>
                </div>

                <!-- drivers -->
                <div v-if="name.mode == 'drivers'">
                  <h5>{{ list.name }}</h5>
                  <p>{{ list.phone }}</p>
                </div>

                <!-- car -->
                <div v-if="name.mode == 'car'">
                  <h5>{{ list.name }}</h5>
                  <p>{{ list.phone }}</p>
                </div>

                <!-- trailers -->
                <div v-if="name.mode == 'trailers'">
                  <h5>{{ list.name }}</h5>
                  <p>{{ list.phone }}</p>
                </div>
              </div>


              <!-- arrow -->
              <div v-if="name.mode == 'orders'"></div>
              <div
                v-else-if="
                  name.mode == 'managers' ||
                  name.mode == 'trailers' ||
                  name.mode == 'car' ||
                  name.mode == 'drivers' ||
                  name.mode == 'carriers' ||
                  name.mode == 'directions' ||
                  name.mode == 'transport_companies'
                "
              >
                <div
                  @click.prevent="openList('update', index)"
                  class="icon icon-arrow-right"
                ></div>
              </div>
              <div v-else>
                <a
                  class="icon icon-arrow-right"
                  @click.prevent="openDelete(index, list.id)"
                ></a>
                <div class="block-list__btn">
                  <a
                    class="icon icon-copy block-list__btn__copy"
                    @click.prevent="openList('copy', index)"
                  ></a>
                  <a
                    class="icon icon-delete block-list__btn__delete"
                    @click.prevent="openList('delete', index)"
                  ></a>
                </div>
              </div>
            </li>
          </ul>
          <ul
            class="block-list block-list__item"
            v-if="name.mode == 'managers'"
          >
            <li
              v-for="(list, index) in contactsOne[0]"
              :key="index"
              @click.prevent="openList('updateOne', index)"
              :data-id="list.id"
            >
              <p style="margin-bottom: 0; padding: 6px 0">{{ list.val }}</p>
              <a class="icon icon-arrow-right"></a>
            </li>
          </ul>
          <a
            class="btn btn-transparent"
            v-if="$root.add.can_add_order && name.mode == 'orders'"
            @click.prevent="openList('create', index)"
            >{{$root.langText.btnAdd}}
            <div class="icon-plus"></div
          ></a>
          <a
            class="btn btn-transparent"
            v-if="name.mode !== 'orders' && $root.edit.can_edit_contractor"
            @click.prevent="openList('create', index)"
            >{{$root.langText.btnAdd}}
            <div class="icon-plus"></div
          ></a>
        </div>
      </div>
    </transition>
    <div v-if="add">
      <Handbook :data="popup2" @closePopup="closePopup" @close="add = false" />
    </div>
    <Message
      v-if="message.popup"
      :message="message"
      @close="message.popup = false"
    />
    <ContactPopup
      v-if="contact_popup"
      :hidden="hidden"
      :mod="'contractor'"
      :id="data.id"
      :modOne="true"
      :data="contact_popupData"
      @closeContact="closeContact"
    />
    <ContactsPerson
      v-if="ContactsPerson.flag"
      :hidden="hidden"
      :info="ContactsPerson"
      :id="data.id"
      :dataPerson="dataPerson"
      @closeContact="closeContact"
      @close="ContactsPerson.flag = false"
      @update="update()"
    />
    <InformationPlates
      v-if="InformationPlates"
      :hidden="$root.edit.can_edit_contractor"
      :data="{ lists, name, data, id: data.id, index, mod }"
      @close="closeList"
    />
  </div>
</template>



<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
  /* .slide-fade-leave-active до версии 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
.block-slide-toggle__btn button::after {
  display: none;
}
</style>


<script>
import InformationPlates from "@/components/counterparties/InformationPlates";
import ContactsPerson from "@/components/contacts/ContactsPerson";
import Message from "@/components/app/Message";
import ContactPopup from "@/components/contacts/ContactPopup";
import Handbook from "@/components/app/Handbook";

export default {
  props: ["data", "name", "hidden"],

  data: () => ({
    fade: true,
    lists: [],
    contactsOne: [],
    contact_popupData: "",
    contact_popup: false,
    listsLength: "",
    InformationPlates: false,
    ContactsPerson: {
      flag: false,
      id: "",
      index: "",
      modPerson: "contractor",
      mod: "create",
    },
    dataPerson: "",
    activeClass: false,
    index: "",
    mod: "",

    //
    add: false,
    popup2: {
      info: "",
      mod: "created",
      contractor_id: "",
      modPopup: "car",
    },

    //
    export_import: "",
    comments: "",

    recommendations: "",
    email: "",
    address: "",

    //message
    message: {
      status: "",
      popup: false,
      message: "",
    },
  }),

  watch: {
    data: function () {
      this.lists = this.data[this.name.bd_name];
      this.contactsOne = [];
      if (
        this.name.mode == "customerCard" ||
        this.name.mode == "recommendations"
      ) {
      } else {
        this.listsLength = this.lists.length;
        this.dataPerson = this.data.contacts_persons;
        var app = this;
        if (this.name.mode == "managers") {
          app.lists = [];
          this.data[this.name.bd_name].forEach(function (item, index) {
            if (item.name) {
              app.lists.push(item);
            } else {
              app.listsLength = +app.listsLength - 1;
              app.contactsOne.push(item.contacts);
            }
          });
        }
      }
    },
  },

  created() {
    if (this.data) {
      this.lists = this.data[this.name.bd_name];
      if (
        this.name.mode == "customerCard" ||
        this.name.mode == "recommendations"
      ) {
      } else {
        this.listsLength = this.lists.length;
        var app = this;
        if (this.name.mode == "managers") {
          app.lists = [];
          this.data[this.name.bd_name].forEach(function (item, index) {
            if (item.name) {
              app.lists.push(item);
            } else {
              app.listsLength = +app.listsLength - 1;
              app.contactsOne.push(item.contacts);
            }
          });
        }
      }
      function isEmptyObject(obj) {
        for (var i in obj) {
          if (obj.hasOwnProperty(i)) {
            return true;
          }
        }
        return false;
      }
      if (isEmptyObject(this.$root.data.counterparties.detail)) {
        var data = this.$root.data.counterparties.detail;
      } else {
        var data = this.data;
      }

      if (this.name.mode == "customerCard") {
        if (data.is_customer_export == 1) {
          this.export_import = 2;
        } else if (data.is_customer_import == 1) {
          this.export_import = 1;
        } else {
          this.export_import = 0;
        }
        this.comments = data.comment;
      }

      if (this.name.mode == "recommendations") {
        if (data.invoice_each_order == 1) {
          this.recommendations = 1;
        } else if (data.invoice_per_month == 1) {
          this.recommendations = 2;
        } else if (data.each_invoice_separately == 1) {
          this.recommendations = 3;
        } else {
          this.recommendations = 0;
        }
        this.email = data.email_invoices;
        this.address = data.mailing_address;
        this.comments = data.comment;
      }
    }
  },

  methods: {
    async closePopup() {
      this.add = false;
      this.$emit("update");
    },

    openDelete(index, id) {
      if (this.$root.edit.can_edit_contractor) {
        var options = document.querySelectorAll("li." + this.name.mode);
        options.forEach(function (item, i) {
          if (i === index) {
            item.classList.toggle("active");
          }
        });
      }
    },

    update() {
      this.$emit("update");
    },

    openList(mod, index) {
      if (this.name.mode == "orders") {
        if (this.data.isClient == 1) {
          localStorage.setItem("AgreementsClient", "client");
          localStorage.setItem("AgreementsClientId", this.data.client_id);
          localStorage.setItem("AgreementsClientName", this.data.client_name);
        }
        if (this.data.is_carrier == 1) {
          if (this.data.is_main_contractor == 1) {
            localStorage.setItem("AgreementsCarrier", "carrier");
            localStorage.setItem("AgreementsCarrierId", this.data.id);
            localStorage.setItem("AgreementsCarrierName", this.data.name);
          } else {
            localStorage.setItem("AgreementsCarrier", "carrier");
            localStorage.setItem(
              "AgreementsCarrierId",
              this.data.main_contractor
            );
            localStorage.setItem(
              "AgreementsCarrierName",
              this.data.main_contractor_name
            );
          }
        }

        if (mod == "update") {
          this.$router.push({
            name: "AgreementsCreateId",
            params: { id: this.data.orders[index].id },
          });
        } else if (mod == "create") {
          this.$router.push("/orders/new");
        }
      } else if (this.name.mode == "carriers") {
        localStorage.setItem("MainContractor", this.data.id);
        if (mod == "update") {
          this.$router.push(
            "/counterparties/" + this.data.subordinate_carriers[index].id
          );
        } else if (mod == "create") {
          this.$router.push("/counterparties/new");
        }
      } else if (this.name.mode == "managers") {
        if (mod == "update") {
          this.ContactsPerson.mod = "update";
          this.ContactsPerson.index = index;
          this.dataPerson = this.data.contacts_persons;
          this.ContactsPerson.id = this.data.id;
          this.ContactsPerson.flag = !this.ContactsPerson.flag;
        } else if (mod == "updateOne") {
          this.contact_popupData = this.contactsOne[0][index];
          this.contact_popup = !this.contact_popup;
        } else {
          this.ContactsPerson.mod = "create";
          this.ContactsPerson.id = this.data.id;
          this.ContactsPerson.index = this.lists.length;
          this.dataPerson = this.data.contacts_persons;
          this.ContactsPerson.flag = !this.ContactsPerson.flag;
        }
      } else if (this.name.mode == "car" || this.name.mode == "trailers" || this.name.mode == "drivers") {
        this.popup2.modPopup = this.name.mode;
        this.popup2.contractor_id = this.data.id;
        if (mod == "update") {
          this.popup2.mod = "update";
          this.popup2.info = this.data[this.name.bd_name][index];
        }
        this.add = !this.add;
      } else if(this.name.mode == "directions" || this.name.mode == "transport_companies"){
        this.mod = mod
        if(mod == 'update'){
          this.index = index
        }
        this.InformationPlates = true
      } 
    },
    async closeContact(data) {
      this.ContactsPerson.flag = false;
      this.contact_popup = false;
      this.$emit("update");
    },
    closeList(data) {
      this.InformationPlates = !this.InformationPlates;
      if (data) {
        this.$emit("update");
      }
    },

    ifStatusText(input) {
      if (input !== null || this.$root.edit.can_edit_contractor) return true;
    },

    async send() {
      if (this.name.mode == "customerCard") {
        var data = {
          comment: this.comments,
          id: this.data.id,
        };
        if (this.export_import == 2) {
          data.is_customer_export = 1;
          data.is_customer_import = 0;
        } else if ((this.export_import = 1)) {
          data.is_customer_export = 0;
          data.is_customer_import = 1;
        }
      }

      if (this.name.mode == "recommendations") {
        var data = {
          mailing_address: this.email,
          email_invoices: this.address,
          id: this.data.id,
          comment: this.comments,
        };
        if (this.recommendations == 1) {
          data.invoice_each_order = 1;
          data.invoice_per_month = 0;
          data.each_invoice_separately = 0;
        } else if (this.recommendations == 2) {
          data.invoice_each_order = 0;
          data.invoice_per_month = 1;
          data.each_invoice_separately = 0;
        } else if (this.recommendations == 3) {
          data.invoice_each_order = 0;
          data.invoice_per_month = 0;
          data.each_invoice_separately = 1;
        }
      }

      if (this.$route.params.id) {
        var update = await this.$store.dispatch("updateСounterparties", data);
        this.$emit("update");

        //messedge
        if (update.data == "ok") {
          this.message.status = update.data;
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.save;
        } else {
          this.message.status = "error";
          this.message.popup = true;
          this.message.message = this.$root.langText.changes.error;
        }
      }
    },
  },

  beforeDestroy() {
    if (this.name.mode == "customerCard") {
      if (this.export_import == 2) {
        this.$root.data.counterparties.detail.is_customer_export = 1;
        this.$root.data.counterparties.detail.is_customer_import = 0;
      } else if ((this.export_import = 1)) {
        this.$root.data.counterparties.detail.is_customer_export = 0;
        this.$root.data.counterparties.detail.is_customer_import = 1;
      }
    }

    if (this.name.mode == "recommendations") {
      this.$root.data.counterparties.detail.mailing_address = this.email;
      this.$root.data.counterparties.detail.email_invoices = this.address;
      if (this.recommendations == 1) {
        this.$root.data.counterparties.detail.invoice_each_order = 1;
        this.$root.data.counterparties.detail.invoice_per_month = 0;
        this.$root.data.counterparties.detail.each_invoice_separately = 0;
      } else if (this.recommendations == 2) {
        this.$root.data.counterparties.detail.invoice_each_order = 0;
        this.$root.data.counterparties.detail.invoice_per_month = 1;
        this.$root.data.counterparties.detail.each_invoice_separately = 0;
      } else if (this.recommendations == 3) {
        this.$root.data.counterparties.detail.invoice_each_order = 0;
        this.$root.data.counterparties.detail.invoice_per_month = 0;
        this.$root.data.counterparties.detail.each_invoice_separately = 1;
      }
    }
    this.$root.data.counterparties.detail.comment = this.comments;
    if (this.data) {
      this.$root.data.counterparties.detail.id = this.data.id;
    }
  },

  components: {
    InformationPlates,
    ContactsPerson,
    Message,
    ContactPopup,
    Handbook,
  },
};
</script>