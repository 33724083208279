<template>
  <div>
    <form class="form">
      <h2>{{$root.langText.info}}</h2>
      
      <div class="form__input">
        <input
          type="text"
          v-model="fields.name"
        />
        <span>{{$root.langText.input.name}}</span>
      </div>


      <div class="form__input" v-if="ifStatusText(fields.nameFull)">
        <p class="form__input__textarea-p">{{fields.nameFull}}</p>
        <textarea class="form__input__textarea"
          type="text"
          v-model="fields.nameFull"
        />
        <span>{{$root.langText.input.fullName}}</span>
      </div>



      
      <Autocomplete 
        :data="{name: $root.langText.input.group, mod: 'counterpartiesGroup', key: 'group', input: fields.group, inputId: fields.group_id}" 
        @result="autocompleteResult" 
        v-if="ifStatusText(fields.group)"
      />

      <div v-if="$root.app == 'SoftProCrmAppRioTransTest' || $root.app == 'SoftProCrmAppRioTrans'">
        <div class="form__row form__row-50">
          <div class="form__checkbox" style="width: 100%" v-if="ifStatusCheckbox(fields.isClient)">
            <label>
              <input 
                type="checkbox"
                v-model="fields.isClient"
              >
              <span>{{$root.langText.input.client}}</span>
            </label>
          </div>

          <div class="form__checkbox" style="width: 100%" v-if="ifStatusCheckbox(fields.is_carrier)">
            <label>
              <input 
                type="checkbox"
                v-model="fields.is_carrier"
              >
              <span>{{$root.langText.input.carrier}}</span>
            </label>
          </div>
        </div>
        <div v-if="fields.is_carrier">
          <div class="form__checkbox" v-if="ifStatusCheckbox(fields.is_main_contractor)">
            <label>
              <input 
                type="checkbox"
                v-model="fields.is_main_contractor"
              >
              <span>{{$root.langText.input.mainCarrier}}</span>
            </label>
          </div>

          <Autocomplete 
            v-if="!fields.is_main_contractor"
            :data="{name: $root.langText.input.mainCarrier, filter: 'is_main', mod: 'counterparties', key: 'main_carrier', input: fields.main_carrier, inputId: fields.main_carrier_id}" 
            @result="autocompleteResult" 
          />
        </div>
      </div>


      <div class="form__row form__row-50">
        <div class="form__radio" v-if="fields.people == 2 || this.$root.edit.can_edit_contractor">
          <label>
            <input type="radio" name="people"
              value="2"
              v-model="fields.people"
            >
            <span>{{$root.langText.input.individual2}}</span>
          </label>
        </div>
        <div class="form__radio" v-if="fields.people == 1 || this.$root.edit.can_edit_contractor">
          <label>
            <input type="radio" name="people"
              value="1"
              v-model="fields.people"
            >
            <span>{{$root.langText.input.legalEntity2}}</span>
          </label>
        </div>
      </div>



      <div class="form__checkbox" v-if="ifStatusCheckbox(fields.resident)">
        <label>
          <input 
            type="checkbox"
            v-model="fields.resident"
          >
          <span>{{$root.langText.input.resident}}</span>
        </label>
      </div>



      <div class="form__input" 
        :class="fields.people == 2 || fields.people == 0 ? 'form__input--disabled' : ''" 
        v-if="ifStatusText(fields.code)"
      >
        <input type="text"
          v-model="fields.code"
        >
        <span>{{$root.langText.input.codeEDRPOU}}</span>
      </div>


      <div class="form__input" 
        :class="fields.people == 0 || fields.people == 1 ? 'form__input--disabled' : ''"
        v-if="ifStatusText(fields.ipn)"
      >
        <input type="text"
          v-model="fields.ipn"
        >
        <span>{{$root.langText.input.ipn}}</span>
      </div>


      <!-- <div class="form__input" 
        :class="fields.people == 0 || fields.people == 2 ? 'form__input--disabled' : ''"
        v-if="ifStatusText(fields.number)"
      >
        <input type="text"
          v-model="fields.number"
        >
        <span>№ свідотства платника податку</span>
      </div> -->


      <h2>{{$root.langText.input.legalAddress}}</h2>
      <Autocomplete 
        :data="{name: $root.langText.input.city, mod: 'city', key: 'legal_city', input: fields.legal_city, inputId: fields.legal_sity_id}" 
        @result="autocompleteResult" 
        v-if="ifStatusText(fields.legal_city)"
      />
      

      <div class="form__input" v-if="ifStatusText(fields.legal_address)">
        <input 
          type="text"
          v-model="fields.legal_address"
        >
        <span>{{$root.langText.input.address}}</span>
      </div>




      <h2>{{$root.langText.input.individualAddress}}</h2>

      <Autocomplete 
        :data="{name: $root.langText.input.city, mod: 'city', key: 'actual_city', input: fields.actual_city, inputId: fields.actual_sity_id}" 
        @result="autocompleteResult" 
        v-if="ifStatusText(fields.actual_city)"
      />

      

      <div class="form__input" v-if="ifStatusText(fields.actual_address)">
        <input 
          type="text"
          v-model="fields.actual_address"
        >
        <span>{{$root.langText.input.address}}</span>
      </div>

      <h2></h2>
      <div class="form__input" v-if="ifStatusText(fields.comments)">
        <p class="form__input__textarea-p">{{fields.comments}}</p>
        <textarea class="form__input__textarea"
          v-model="fields.comments"
        />
        <span>{{$root.langText.input.comments}}</span>
      </div>

      <a class="btn-save btn btn-primary" href="#" @click.prevent="sendForm">{{$root.langText.save}}</a>
      <Message v-if="message.popup" :message="message" @close="message.popup = false"/>
    </form>
    <!-- <Clients @close="closeList" :input="input" v-if="clients" /> -->
  </div>
</template>


<style lang="scss">
  .form__input__textarea{
    position: absolute;
    bottom: 0;
    width: 100%;
    height: calc(100% - 15px);
    left: 0;
    background: none;
    overflow: hidden;
  }
  .form__input__textarea-p{
    position: relative;
    z-index: -1;
    min-height: 25px;
    opacity: 0;
    padding-bottom: 9px !important;
  }
  

</style>



<script>
import {required} from 'vuelidate/lib/validators'
import Autocomplete from "@/components/app/Autocomplete"
import Message from "@/components/app/Message"


export default {
  props: ['id'],
  data: () => ({
    fields: {
      name: null,
      nameFull: null,
      group: '',
      group_id: '',
      isClient: '',
      is_carrier: '',
      people: '0',
      resident: false,
      is_main_contractor: false,
      main_carrier: '',
      main_carrier_id: '',
      code: null,
      ipn: null,
      number: null,


      legal_city: null,
      legal_city_id: null,
      legal_city_img: '',
      legal_address: null,

      
      actual_city: null,
      actual_city_id: null,
      actual_address: null,


      comments: null
    },

    counterpartiesId: null,

    //message
    message: {
      status: '',
      popup: false,
      message: ''
    }
  }), 

  
  watch: {
    id() {
      if(this.$route.params.id){
        function boolean(val){
          return (val == 0) ? (false) : true
        }
        //counterpartiesId
        this.counterpartiesId = this.id

        //get data
        this.fields.name = this.counterpartiesId.name
        this.fields.nameFull = this.counterpartiesId.fullName
        this.fields.group = this.counterpartiesId.group_name
        this.fields.group_id = this.counterpartiesId.group_id
        this.fields.isClient = boolean(this.counterpartiesId.isClient)
        this.fields.is_carrier = boolean(this.counterpartiesId.is_carrier)
        this.fields.is_main_contractor = boolean(this.counterpartiesId.is_main_contractor)
        this.fields.main_carrier = this.counterpartiesId.main_contractor_name
        this.fields.main_carrier_id = this.counterpartiesId.main_contractor
        this.fields.people = this.counterpartiesId.entity_physical_person
        this.fields.resident = boolean(this.counterpartiesId.not_resident)

        this.fields.code = this.counterpartiesId.okpo
        this.fields.ipn = this.counterpartiesId.inn
        // this.fields.number = this.counterpartiesId.


        this.fields.legal_city = this.counterpartiesId.city_name
        this.fields.legal_city_id = this.counterpartiesId.city_id
        this.fields.legal_address = this.counterpartiesId.physical_address


        this.fields.actual_city = this.counterpartiesId.physical_city_name
        this.fields.actual_city_id = this.counterpartiesId.physical_city_id
        this.fields.actual_address = this.counterpartiesId.address

        this.fields.comments = this.counterpartiesId.comment
      }
    }
  },

  
  created() {
    if(this.$route.params.id){
      function boolean(val){
        return (val == 0) ? (false) : true
      }

      if(this.$root.data.counterparties.info){
        this.counterpartiesId = this.$root.data.counterparties.info
      } else{
        this.counterpartiesId = this.id
      }


      //get data
      this.fields.name = this.counterpartiesId.name
      this.fields.nameFull = this.counterpartiesId.fullName
      this.fields.group = this.counterpartiesId.group_name
      this.fields.group_id = this.counterpartiesId.group_id
      this.fields.isClient = boolean(this.counterpartiesId.isClient)
      this.fields.is_carrier = boolean(this.counterpartiesId.is_carrier)
      this.fields.is_main_contractor = boolean(this.counterpartiesId.is_main_contractor)
      this.fields.main_carrier = this.counterpartiesId.main_contractor_name
      this.fields.main_carrier_id = this.counterpartiesId.main_contractor
      this.fields.people = this.counterpartiesId.entity_physical_person
      this.fields.resident = boolean(this.counterpartiesId.not_resident)
      this.fields.code = this.counterpartiesId.okpo
      this.fields.ipn = this.counterpartiesId.inn
      // this.fields.number = this.counterpartiesId.


      this.fields.legal_city = this.counterpartiesId.city_name
      this.fields.legal_city_id = this.counterpartiesId.city_id
      this.fields.legal_address = this.counterpartiesId.physical_address


      this.fields.actual_city = this.counterpartiesId.physical_city_name
      this.fields.actual_city_id = this.counterpartiesId.physical_city_id
      this.fields.actual_address = this.counterpartiesId.address

      this.fields.comments = this.counterpartiesId.comment
    }
  }, 



  methods: {

    //hidden inputs
    ifStatusText(input){
      if(input !== null || this.$root.edit.can_edit_contractor) return true
    },
    ifStatusCheckbox(input){
      if(input || this.$root.edit.can_edit_contractor) return true
    },



    //autocomplete
    autocompleteResult(data){
      this.fields[data.input] = data.name
      this.fields[data.input + '_id'] = data.id
      this.fields[data.input + '_img'] = data.img
    },

    //send data
    async sendForm(){
      function boolean(val){
        return (val == false) ? 0 : 1
      }

      var data = {
        name: this.fields.name || '',
        full_name: this.fields.nameFull || '',
        group_id: this.fields.group_id,
        is_client: boolean(this.fields.isClient),
        entity_physical_person: this.fields.people,
        not_resident: boolean(this.fields.resident),
        okpo: this.fields.code,
        inn: this.fields.ipn,
        // name: this.fields.number,

        city_id: this.fields.legal_city_id,
        physical_address: this.fields.legal_address,

        physical_city_id: this.fields.actual_city_id,
        address: this.fields.actual_address,

        comment: this.fields.comments
      }
      //
      if(this.fields.isClient && !this.fields.is_carrier){
        data.is_carrier = 0
        data.is_main_contractor = 0
        data.main_contractor = ''
      }
      if(this.fields.is_carrier && !this.fields.is_main_contractor){
        data.is_carrier = 1
        data.is_main_contractor = 0
        data.main_contractor = this.fields.main_carrier_id || ''
      } 
      if(this.fields.is_main_contractor){
        data.is_main_contractor = 1
        data.is_carrier = 1
        data.main_contractor = ''
      }
      if(!this.fields.is_carrier){
        data.is_carrier = 0
        data.is_main_contractor = 0
        data.main_contractor = ''
      }

      
      if(localStorage.getItem('MainContractor')){
        data.main_contractor = localStorage.getItem('MainContractor')
      }

      if(this.$route.params.id){
        data.id = this.counterpartiesId.id
        var update = await this.$store.dispatch('updateСounterparties', data);
        this.$emit('updateData')

        //messedge
        if(update.data == 'ok'){
          this.message.status = update.data
          this.message.popup = true
          this.message.message = this.$root.langText.changes.save
        } else{
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.error
        }
      }else{
        if(localStorage.getItem('clientId')){
          data.client_id = localStorage.getItem('clientId')
        }
        var create = await this.$store.dispatch('createСounterparties', data)
        if(create.data.status == 'error'){
          this.message.status = 'error'
          this.message.popup = true
          this.message.message = this.$root.langText.changes.fillIn
        } else{
          if(localStorage.getItem('MainContractor') || localStorage.getItem('clientId')){
            this.$router.go(-1)
          } else{
            this.$router.push('/counterparties')
          }
        }
        
      }
    }
  },

  beforeDestroy() {
    function boolean(val){
      return (val == false) ? 0 : 1
    }
    var data = {
      name: this.fields.name || '',
      fullName: this.fields.nameFull || '',
      group_name: this.fields.group || '',
      group_id: this.fields.group_id,
      entity_physical_person: this.fields.people,
      not_resident: boolean(this.fields.resident),
      okpo: this.fields.code,
      inn: this.fields.ipn,
      isClient: boolean(this.fields.isClient),
      is_carrier: boolean(this.fields.is_carrier),
      is_main_contractor: boolean(this.fields.is_main_contractor),
      main_contractor_name: this.fields.main_carrier,
      main_contractor: this.fields.main_carrier_id,
      // name: this.fields.number,

      city_id: this.fields.legal_city_id,
      city_name: this.fields.legal_city || '',
      physical_address: this.fields.legal_address,

      physical_city_name: this.fields.actual_city,
      physical_city_id: this.fields.actual_city_id,
      address: this.fields.actual_address,

      comment: this.fields.comments
    }
    if(this.counterpartiesId){
      data.id = this.counterpartiesId.id
    }
    this.$root.data.counterparties.info = data
  },


  components:{
    Autocomplete, Message
  }
}
</script>


